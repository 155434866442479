.login-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../images/login-background.jpeg') no-repeat;
    background-size: cover;
    z-index: -1;
}

.main-login {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
}

.login-error {
    background: var(--red-100);
    color: var(--white-100);
    height: 42px;
    border-radius: 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-message {
    background: var(--green-100);
    color: var(--white-100);
    height: 42px;
    border-radius: 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-container {
    background: var(--white-100);
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
    padding: 35px;
    border-radius: 20px;
    min-width: 500px;
}

.img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0 35px 0;
}

.login-form h1 {
    font-size: 1.7em;
    font-weight: 800;
}

.welcome-message {
    font-size: 0.9em;
    color: var(--blue-100);
}

.custom-hr {
    margin: 40px 0 30px 0;
    height: 1px;
    width: 100%;
    background: var(--black-5);
}

.required {
    color: var(--red-100);
}

.login-form label {
    font-size: 0.9em;
    font-weight: 500;
}

.login-form input {
    border:0;
    background: var(--black-5);
    border-radius: 10px;
    height: 45px;
    padding: 0 20px;
}

.login-form input::placeholder {
    font-size: 0.9em;
}

.forgot-password {
    font-size: 0.8em;
    color: var(--black-70);
}

.login-button-container {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}

.btn.btn-primary.login-button {
    border-radius: 10px;
    background: var(--blue-100);
    font-weight: 800;
    height: 40px;
    text-transform: uppercase;
    font-size: 0.9em;
    padding: 0 45px 0 20px;
    position: relative;
    border: 0;
}

.btn.btn-primary.login-button-loading {
    border-radius: 10px;
    background: var(--blue-100);
    font-weight: 800;
    height: 40px;
    text-transform: uppercase;
    font-size: 0.9em;
    padding: 0 20px;
    position: relative;
    border: 0;
}

.btn.btn-primary.login-button:hover {
    background: var(--blue-120);
}

.btn.btn-primary.login-button:before {
    content: '';
    position: absolute;
    right: 14px;
    top: 10px;
    width: 20px;
    height: 22px;
    background: url('../icons/login.svg') no-repeat;
    background-size: contain;
}