.main-app {
    width: 100%;
    height: 100vh;
    display: flex;
    overflow: hidden;
}

.main-app-right-part {
    width: calc(100% - 220px);
}

.main-menu {
    height: 100%;
    width: var(--menu-size);
    border-right: 1px solid var(--black-5);
}

.main-navbar, .app-logo {
    height: 80px;
    border-bottom: 1px solid var(--black-5);
}

.main-navbar {
    display: flex;
}

.app-logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-item {
    height: 75px;
    display: flex;
    align-items: center;
    padding-left: 60px;
    text-decoration: none;
    color: var(--black-100);
    font-weight: 500;
    transition: all .1s;
    position: relative;
    z-index: 0;
}

.menu-item:before {
    content: '';
    position: absolute;
    left: 21px;
    top: 28px;
    width: 22px;
    height: 22px;
    background: url('../icons/projects.svg') no-repeat;
    background-size: contain;
}

.menu-item.orders:before {
    background: url('../icons/orders.svg') no-repeat;
    left: 23px;
}

.menu-item.customers:before {
    background: url('../icons/customers.svg') no-repeat;
    width: 25px;
    height: 26px;
    top: 27px;
}

.menu-item.suppliers:before {
    background: url('../icons/suppliers.svg') no-repeat;
    width: 25px;
    height: 26px;
    top: 27px;
}

.menu-item:hover, .menu-item.active {
    background: var(--black-5);
}

.menu-link:hover {
    text-decoration: none;
}

.new-button {
    position: absolute;
    right: 18px;
    top: 17px;
    font-size: 28px;
    font-weight: 200;
    color: var(--black-70);
    transition: all .5s;
    z-index: 2;
}

.new-button:hover {
    color: var(--white-100);
}

.new-button:before {
    content: '';
    position: absolute;
    top: 9px;
    right: -5px;
    width: 26px;
    height: 26px;
    border: 1px solid var(--black-15);
    border-radius: 9px;
    z-index: -1;
    transition: all .5s;
}

.new-button:hover:before {
    background: var(--blue-100);
    border: 1px solid var(--blue-100);
}

.searchbar-container {
    width: 100%;
    padding: 20px;
}

.searchbar-item input {
    height: 40px;
    width: 100%;
    border: 1px solid var(--black-15);
    border-radius: 12px;
    background: var(--black-5);
    padding: 0 20px 0 40px;
    font-weight: 500;
    font-size: 0.9em;
}

.searchbar-item {
    position: relative;
}

.searchbar-item:before {
    content: '';
    position: absolute;
    left: 12px;
    top: 12px;
    width: 18px;
    height: 22px;
    background: url('../icons/search.svg') no-repeat;
    background-size: contain;
}

.user-info-container {
    border-left: 1px solid var(--black-5);
    padding: 20px;
}

.user-item {
    height: 40px;
    background: var(--blue-20);
    border-radius: 12px;
    display: flex;
    align-items: center;
    font-size: 0.9em;
    font-weight: 700;
    padding: 0 50px 0 40px;
    white-space: nowrap;
    position: relative;
    min-width: 200px;
    cursor: pointer;
    transition: all .3s;
    color: var(--blue-170);
}

.user-item:hover {
    background: var(--blue-100);
    color: var(--white-100);
}

.user-item:hover:before {
    background: url('../icons/account-white.svg') no-repeat;
}

.user-item:hover:after {
    background: url('../icons/arrow-toggle-white.svg') no-repeat;
}

.user-item:before {
    content: '';
    position: absolute;
    left: 12px;
    top: 9px;
    width: 17px;
    height: 22px;
    background: url('../icons/account.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.user-item:after {
    content: '';
    position: absolute;
    right: 12px;
    top: 16px;
    width: 15px;
    height: 22px;
    background: url('../icons/arrow-toggle.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.user-options a {
    text-decoration: none;
}

.user-options-item {
    height: 40px;
    border: 1px solid var(--blue-60);
    width: 200px;
    border-radius: 12px;
    margin-top: 6px;
    background: var(--white-100);
    padding-left: 40px;
    color: var(--blue-100);
    font-size: 0.9em;
    font-weight: 600;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: all .3s;
    text-decoration: none;
}

.user-options-item:hover {
    background: var(--blue-20);
    color: var(--blue-100);
}

.user-options-item:before {
    content: '';
    position: absolute;
    left: 12px;
    top: 9px;
    width: 20px;
    height: 22px;
    background: url('../icons/settings.svg') no-repeat;
    background-size: contain;
}

.user-options-item.launch {
    border: 1px solid var(--green);
    color: var(--green);
}

.user-options-item.launch:hover {
    color: white;
    background: var(--green);
}

.user-options-item.logout, .user-options-item.delete {
    border: 1px solid var(--red-100);
    color: var(--red-100);
}

.user-options-item.logout:before {
    background: url('../icons/logout--red.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
    top: 11px;
    left: 14px;
    width: 18px;
}

.user-options-item.logout:hover, .user-options-item.delete:hover {
    background: var(--red-100);
    color: var(--white-100);
}

.user-options-item.logout:hover:before {
    background: url('../icons/logout--white.svg') no-repeat;
    background-size: contain;
}

.user-options-item.launch:hover:before {
    background: url('../icons/launch--white.svg') no-repeat;
    background-size: contain;
}

.user-options-item.link:before {
    background: url('../icons/link--blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
    height: 18px;
    top: 10px;
}

.user-options-item.launch:before {
    background: url('../icons/launch--green.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
    height: 20px;
    top: 9px;
}

.user-options-item.delete:before {
    background: url('../icons/delete--red.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
    top: 11px;
    left: 13px;
    width: 16px;
}

.user-options-item.delete:hover:before {
    background: url('../icons/delete--white.svg') no-repeat;
    background-size: contain;
}

.user-options-item.archive:before {
    background: url('../icons/archive--blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
    top: 12px;
    left: 12px;
    width: 19px;
}